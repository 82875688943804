import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import { Link, graphql } from "gatsby";
import { css } from "@emotion/react";
import BuildGif from "./../images/programming/build.gif";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "programming"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcselection/programming.png"}) { ...eyecatchImg },
    programming_sakkaku: file(relativePath: { eq: "programming/programming_sakkaku.png"}) { ...normalImg },
    unity: file(relativePath: { eq: "programming/unity.png"}) { ...normalImg },
    cssd_size: file(relativePath: { eq: "cover/pcparts/ssd_size.PNG"}) { ...eyecatchImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    typec_desktop: file(relativePath: { eq: "programming/typec_desktop.png"}) { ...normalImg },
    ai: file(relativePath: { eq: "programming/ai.png"}) { ...normalImg },
    science: file(relativePath: { eq: "programming/science.png"}) { ...normalImg },
    macbook: file(relativePath: { eq: "programming/macbook.png"}) { ...normalImg },
    ryzen: file(relativePath: { eq: "programming/ryzen.png"}) { ...normalImg },
    wsl_memory: file(relativePath: { eq: "programming/wsl_memory.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`【現役プログラマが教える】プログラミングにおすすめのパソコン`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="プログラミングにおすすめのパソコン" mdxType="Image" />
    <p>{`プログラミングに適したパソコンといっても、扱うプログラミング言語、記述するコードが大規模なものか、データ量は多いかなどによって異なり一概には言えない。`}</p>
    <p>{`しかし最低限のスペック、コードを見る領域を広くとれるモニターなど、行うプログラミングの種類によらず話せることも多い。`}</p>
    <p>{`そこでWeb系、AI学習、ゲームなどプログラミングの種類ごとに適切なパソコンの特徴を述べ、その後プログラミングを行う上で共通して必要な最低限のスペックや、プログラミングにおすすめのパソコンを紹介をしていく。`}</p>
    <p>{`読み終えると、自分のやりたいことや予算に応じたパソコンを選べるようになるはずである。それでは始めていきたい。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "プログラミングの種類による最適なOSとGPUの選択",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%E3%81%AE%E7%A8%AE%E9%A1%9E%E3%81%AB%E3%82%88%E3%82%8B%E6%9C%80%E9%81%A9%E3%81%AAOS%E3%81%A8GPU%E3%81%AE%E9%81%B8%E6%8A%9E",
        "aria-label": "プログラミングの種類による最適なOSとGPUの選択 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`プログラミングの種類による最適なOSとGPUの選択`}</h2>
    <p>{`プログラミングで何を作りたいか？あるいは何の解析を行いたいのかなどの目的により、使用するOSとGPU搭載をすべきか否かが異なってくる。`}</p>
    <p>{`プログラミングの種類をパソコンの構成に影響を及ぼす観点から分別するとWebプログラミング、iOSプログラミング、科学技術計算、AIプログラミング、(高度な)3Dゲームプログラミングの５つに分けることができ、それは下記のようになる。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <caption>プログラミングの種類別おすすめパソコン</caption>
  <thead>
    <tr>
			<th>OS</th>
			<th colSpan="2">Windows</th>
			<th>Mac</th>
		</tr>
		<tr>
			<th>GPUの有無</th>
			<th>GPU無</th>
			<th>GPU有</th>
			<th>N/A</th>
		</tr>
  </thead>
	<tbody>
		<tr>
			<td>Webプログラミング</td>
			<td>◎</td>
			<td>◎</td>
			<td>◎</td>
		</tr>
		<tr>
			<td>iOSプログラミング</td>
			<td>×</td>
			<td>×</td>
			<td>◎</td>
		</tr>
		<tr>
			<td>科学技術計算</td>
			<td>〇</td>
			<td>◎</td>
			<td>△</td>
		</tr>
		<tr>
			<td>AIプログラミング</td>
			<td>〇</td>
			<td>◎</td>
			<td>〇</td>
		</tr>
		<tr>
			<td>3Dゲームプログラミング</td>
			<td>△</td>
			<td>◎</td>
			<td>△</td>
		</tr>
	</tbody>
    </BasicTable>
◎ 適している 〇 可能 △ 厳しい場合がある × 不可能
    <p>{`GPUはグラフィックボード、グラボとも言われ、3Dの描画を高速にする目的で発展してきたが、本質的には並列に大量の演算を可能とするプロセッサーであり、行列演算が多いAIの学習や、科学技術の計算を桁違いで高速化することが可能である。`}</p>
    <p>{`次の節では、プログラミングの種類を具体例を踏まえてもう少し詳細に解説する他、何が適しているのか、何が厳しいのかなど表の理由を説明していく。`}</p>
    <h3 {...{
      "id": "Web系プログラミング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Web%E7%B3%BB%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0",
        "aria-label": "Web系プログラミング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Web系プログラミング`}</h3>
    <Image {...props} name="programming_sakkaku" alt="Web開発はWindowsで簡単にできる件" mdxType="Image" />
    <p><strong parentName="p"><em parentName="strong">{`WindowsとMacどちらで行っても大して差は無く、いずれも快適に開発できる。`}</em></strong></p>
    <p>{`Webプログラミングは`}<em parentName="p">{`JavaScript/HTML/CSS`}</em>{`などWebブラウザで動作可能な言語の他、`}<a parentName="p" {...{
        "href": "https://www.php.net/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`PHP`}</a>{`、`}<a parentName="p" {...{
        "href": "https://www.ruby-lang.org/ja/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Ruby`}</a>{`、`}<a parentName="p" {...{
        "href": "https://www.python.jp/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Python`}</a>{`、`}<a parentName="p" {...{
        "href": "https://www.java.com/ja/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Java`}</a>{`、`}<a parentName="p" {...{
        "href": "https://nodejs.org/ja/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Nodejs`}</a>{`などサーバーサイドで動作する言語も含む。Webサービスを提供するためのプログラミング全般である。`}</p>
    <p>{`2018年頃まではWindowsで作成したプログラムをLinuxのレンタルサーバーに置くと、互換性の問題で動かないことが多かったので、その煩わしさを回避する目的でMac一択であった。`}</p>
    <p>{`しかし今はWindowsで`}<em parentName="p"><a parentName="em" {...{
          "href": "https://docs.microsoft.com/ja-jp/windows/wsl/",
          "target": "_blank",
          "rel": "nofollow noopener"
        }}>{`WSL`}</a>{`(Windows SubSystem Linux)`}</em>{`というLinuxを動かす仕組みがサポートされ、WSL2ではLinuxの根幹に当たるLinux KernelをWindowsに取り込むことで、それまで課題であった動作速度も改善された。`}</p>
    <p>{`これにより`}<strong parentName="p"><em parentName="strong">{`WindowsとMacどちらでも慣れた方を使えば良い`}</em></strong>{`という状況が今である。`}</p>
    <p>{`個人的な見解で言うと、Macはノートパッドでの操作が優れており、ページの切り替えがスムーズかつ滑らかに行えるため、マウスの場所も惜しいなど、外出先で使うことが多い場合はMacをおすすめする。感覚としては７割以上は外で使う人はMacが良いと思われる。`}</p>
    <p>{`カフェマックはプログラミングに適しているが、カフェWindowsを行おうとは特に私は思わない。プログラマーがカフェでMacを使っているのは別にイキっている訳ではなく、マウスの代わりにコーヒーカップを置くことができるからである。`}</p>
    <p>{`Windowsはマウスを使えば扱いやすく、ファイル操作は簡単でFinderというMacの使いにくいファイルエクスプローラーを使う必要は無い。特にデュアルディプレイにするとMacは操作がやりづらい。`}</p>
    <p>{`という事で、`}<strong parentName="p"><em parentName="strong">{`強いて言うならば外での使用が大部分で、のまど的なパソコンの使い方をするならばMac、基本的に作業スペースが確保できるようならばWindows`}</em></strong>{`が良いとは個人的には思う。`}</p>
    <h3 {...{
      "id": "iOSプログラミング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#iOS%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0",
        "aria-label": "iOSプログラミング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iOSプログラミング`}</h3>
    <Image {...props} name="macbook" alt="iOSプログラミング" mdxType="Image" />
    <p>{`AppleのApp Storeへ向けてiOSのアプリケーションを開発するにはMacが必要となり、アップルの`}<a parentName="p" {...{
        "href": "https://developer.apple.com/jp/support/enrollment/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`公式サポート`}</a>{`でも極めて重要であると書かれている。`}</p>
    <p>{`iOSアプリ開発用に開発されたプログラミング言語SwiftではもちろんMacが必要であるが、WebアプリケーションフレームワークのReactをBaseとした`}<a parentName="p" {...{
        "href": "https://reactnative.dev/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`ReactNative`}</a>{`や、ゲームに特化した`}<a parentName="p" {...{
        "href": "https://unity.com/ja",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Unity`}</a>{`などクロスプラットフォーム環境で開発した場合でも`}<strong parentName="p"><em parentName="strong">{`最終的にアプリをリリースするためにはMacが必須`}</em></strong>{`となってくる。`}</p>
    <p>{`クロスプラットフォーム開発のため、開発はWindowsでしたいが、リリースはMacという場合には`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/ec2/instance-types/mac/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Amazon EC2 Macのインスタンス`}</a>{`を用いてクラウド上で作業するという技もある。`}</p>
    <p>{`ただ、価格が１時間当たり1.3ドル程度であったため、慣れないマックで作業してリリースのために3日かかったとすると１日8時間で39ドル、4300円程度掛かるということになる。まあ`}<strong parentName="p"><em parentName="strong">{`慣れないうちはあまりおすすめはしない`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "科学技術計算",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%A7%91%E5%AD%A6%E6%8A%80%E8%A1%93%E8%A8%88%E7%AE%97",
        "aria-label": "科学技術計算 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`科学技術計算`}</h3>
    <Image {...props} name="science" alt="科学技術計算プログラミング" mdxType="Image" />
    <p>{`分野にもよると思うが、プログラミング言語としてはC、C++、Fortran、`}<a parentName="p" {...{
        "href": "https://jp.mathworks.com/products/matlab.html",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Matlab`}</a>{`当たりが良く使われている。`}</p>
    <p>{`おおよそCPUだけで問題は無いが、流体力学で波の動きをシミュレーション、分子モデリング、災害予測など、`}<strong parentName="p">{`3D表現や大量のデータを取り扱う場合には並列に多くの計算をこなす事ができるGPUの力を借りる機会も多い`}</strong>{`。`}</p>
    <p>{`CPUからGPUを呼び出す言語としては`}<a parentName="p" {...{
        "href": "https://developer.nvidia.com/cuda-toolkit",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`CUDA`}</a>{`というNvidiaのプログラミング言語が用いられるが、`}<strong parentName="p"><em parentName="strong">{`AppleはNvidiaと決別しているため、CUDAを扱うことはできない`}</em></strong>{`。`}</p>
    <p>{`また、科学技術計算に関して便利な機能を提供するフリーアプリがWindowsには複数存在するのに対してMacには存在しないことも多いため、`}<strong parentName="p"><em parentName="strong">{`これから使うソフトが何か分からない理工系の人はWindowsが無難`}</em></strong>{`である。`}</p>
    <p>{`MacからWindowsを起動する方法もあるが、M1チップ搭載のMacでは互換性問題もあり、またOSが異なることで動作が遅くなるためおすすめはできない。`}</p>
    <h3 {...{
      "id": "AIプログラミング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#AI%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0",
        "aria-label": "AIプログラミング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`AIプログラミング`}</h3>
    <Image {...props} name="ai" alt="AIプログラミング" mdxType="Image" />
    <p>{`AIの基本は正解付きの大量のデータをコンピュータに学習させることで、未知のデータが来た時の予測を可能とするものである。`}</p>
    <p>{`高い精度を得るためには、学習時にデータセットを少しずつ変更しながら何回も学習のループを繰り返す必要があるため、`}<strong parentName="p"><em parentName="strong">{`AIの学習はデータ数が大して多くない場合でも数分程度は普通にかかってくる`}</em></strong>{`。`}</p>
    <p>{`精度の高い学習済のモデルを作成するためには、学習のループを一定数回すこと以外、様々なアルゴリズムの選択や学習係数などパラメータをチューニング(ハイパーパラメータチューニングという)していく必要があるため、これらの計算を如何に速くできるかが効率化には重要となる。`}</p>
    <p>{`ここで`}<strong parentName="p"><em parentName="strong">{`GPUの力を借りると、桁違いで学習時間を短縮できる`}</em></strong>{`ため、無くても問題はないものの本格的にAIの開発を行いたい場合にはGPUは必須である。`}</p>
    <p>{`やや余談ではあるが、AIの学習過程で計算精度はあまり要求されないことから、精度が悪い代わりに高速に計算できるTensorCoreというプロセッサがAI専用のGPUでは多く搭載される。`}</p>
    <p>{`個人でも`}<a parentName="p" {...{
        "href": "https://amzn.to/2V3tML2",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Nvidia Titan`}</a>{`などAI専用のGPUは入手は可能であるが、値段が高いのであまり現実的ではない。研究室で予算が出るなら購入を検討するというぐらいだろう。`}</p>
    <p>{`更に余談だが、このページのいくつかの画像はAIで作成した。`}</p>
    <h3 {...{
      "id": "ゲームプログラミング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0",
        "aria-label": "ゲームプログラミング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲームプログラミング`}</h3>
    <Image {...props} name="unity" alt="ゲームのプログラミング" mdxType="Image" />
    <p>{`簡単な2Dゲームを作る程度であれば、どのような統合開発環境やプログラミングを使っても可能であるが、現在市場に出ているような複雑で高度なグラフィックのゲームを一から作るには膨大な時間が掛かる。`}</p>
    <p>{`このため様々な場面で共通して使用できる処理を再利用可能な形に整えた`}<em parentName="p">{`ゲームエンジン`}</em>{`を用いて効率的な開発が行われる。`}<em parentName="p"><a parentName="em" {...{
          "href": "https://unity.com/ja",
          "target": "_blank",
          "rel": "nofollow noopener"
        }}>{`Unity`}</a></em>{`と`}<em parentName="p"><a parentName="em" {...{
          "href": "https://www.unrealengine.com/ja/unreal",
          "target": "_blank",
          "rel": "nofollow noopener"
        }}>{`Unreal`}</a></em>{`が多くのゲームで採用されており、両方ともクロスプラットフォーム開発が可能である。`}</p>
    <p>{`Unityは2005年にMac OS向けに制作されたゲームエンジンであり、スマホの2Dゲームから比較的高度なゲームを簡単に作成できることから人気を得ており、パズドラ、FGO、ウマ娘など日本のスマホ向けゲームの多くはUnityで作られている。`}</p>
    <p>{`一方でUnreal Engineはフォートナイトで有名なEpic Gamesが開発しているゲームエンジンであり、FF VIIのリメイク、テイルズの最新作等はUnreal Engineを採用しているほかドラクエXIIでも採用される。`}</p>
    <p>{`PCスペックとしては、`}<strong parentName="p"><em parentName="strong">{`スマホ向けゲームをUnityで作成したいというのならばGPU無でも問題は無い`}</em></strong>{`が、PCやPlayStation/Xboxなどのコンソールに向けて高度な描画を行いたいならば、ゲーミングパソコンに搭載されるような`}<strong parentName="p"><em parentName="strong">{`相応のGPUを搭載することが求められる`}</em></strong>{`。`}</p>
    <p>{`また、WindowsかMacでは、Windowsをおすすめする。NvidiaのGPUが安価で調達できる他、Windowsが業界標準となっているため情報量も豊富であり、エディタもVisualStudioが使いやすい。iOS向けのアプリケーションを作りたい以外はWindowsが良いだろう。`}</p>
    <h2 {...{
      "id": "プログラミングに必要な最低限のスペック",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%E3%81%AB%E5%BF%85%E8%A6%81%E3%81%AA%E6%9C%80%E4%BD%8E%E9%99%90%E3%81%AE%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF",
        "aria-label": "プログラミングに必要な最低限のスペック permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`プログラミングに必要な最低限のスペック`}</h2>
    <p>{`プログラミングの初学者が、C言語を勉強しようとGCCコンパイラをWindowsパソコンに入れて、Hello World!と出力するプログラミングを作成する。これならば数世代前のCeleronで問題ない。`}</p>
    <p>{`同様に大学の初学者向けプログラミング授業、あるいは基本情報技術者試験などを目指し基本のプログラミングをを学ぶというのならばどのようなパソコンでも問題は無い。純粋にこれらのプログラミングだけを行うためにパソコンを購入するならば４万円のパソコンでも十分である。`}</p>
    <p>{`しかし、実際にWebの開発であれ、WindowsやiOSアプリの開発であれプロダクトレベルの開発を行っていこうとすると`}<strong parentName="p"><em parentName="strong">{`フレームワークを使用しての開発がメインとなるので、ある程度パソコンのスペックが求められる`}</em></strong>{`ようになる。まずはこの最低限のスペックを定義したい。`}</p>
    <h3 {...{
      "id": "メモリは16GB",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AF16GB",
        "aria-label": "メモリは16GB permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリは16GB`}</h3>
    <p>{`プログラミングはエディタでの編集を行い、結果をコンソールやブラウザなどで確認する。また分からない箇所があればグーグルで検索をして調査を行う必要がある。すなわち３画面、４画面は当たり前のように開いている状態となるのが普通である。`}</p>
    <p>{`これらの状態を保持するためには多くのメモリを必要とする。メモリが足りていないとメモリの代わりにストレージを使用するスラッシングが多発して低速化するか、メモリ不足のエラー(Out of Memory Error)が表示されてアプリケーションが終了するかのいずれかである。`}</p>
    <p>{`ということで`}<strong parentName="p"><em parentName="strong">{`最低8GBは快適なプログラミングのためには必要`}</em></strong>{`であろう。4GBだと不要なWindowをちまちまと消して、メモリ状態を常に意識しながらコードを書いていく必要があるため面倒である。`}</p>
    <p>{`ただ、特にWebプログラミング環境でWSLやDockerといった`}<strong parentName="p"><em parentName="strong">{`仮想環境を作る機会が多いことからやはり16GBが望ましい`}</em></strong>{`。`}</p>
    <p>{`例えば下記はWSL2の消費メモリのキャプチャであるが、`}<strong parentName="p"><em parentName="strong">{`特に何もしていない状態で4GB程度を確保されてしまっている`}</em></strong>{`。仮想環境に渡すメモリ量は制限可能であるが、パフォーマンスの低下を招くため、メモリの余裕を持たせることも重要である。`}</p>
    <Image {...props} name="wsl_memory" caption="WSL2のメモリ消費量" mdxType="Image" />
    <p>{`その他大量のデータを扱う場合はOut of Memory(メモリー不足)のエラーが発生しやすいため、ビッグデータの解析や数千件以上データがあるデータベースを操作する場合には16GB積んでも良いだろう。`}</p>
    <h3 {...{
      "id": "ストレージはSSD",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B9%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B8%E3%81%AFSSD",
        "aria-label": "ストレージはSSD permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ストレージはSSD`}</h3>
    <p>{`ストレージは高速で高価なSSDと低速で大容量のHDDの２つがパソコン用として使われている。SSDでないとアプリケーションの起動やファイルの読み取り速度が若干遅くなるので、快適に操作をするならばSSD搭載の機種を選ぶべきである。`}</p>
    <p>{`もっとも最近のノートパソコンもデスクトップパソコンもSSDが主流となっているので知らずに選んでもSSDが搭載されている可能性の方が高いだろう。`}</p>
    <p>{`容量は256GBあれば十分だと思うが、ゲーム開発を考えている人と、余裕を持ちたい人は512GB以上がおすすめである。`}</p>
    <p>{`ただ、プログラミングだけのためにパソコンを買うわけではないと思うので、下記の記事を参考にして欲しい。`}</p>
    <Card {...props} title="【256GB,512GB,1TB】SSDの容量のおすすめは何か？" description="ストレージの容量を決めかねている場合に参考になる情報を網羅" image="cssd_size" path="/ssd/" mdxType="Card" />
    <h3 {...{
      "id": "CPUはPassMarkで10000点",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU%E3%81%AFPassMark%E3%81%A710000%E7%82%B9",
        "aria-label": "CPUはPassMarkで10000点 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUはPassMarkで10000点`}</h3>
    <p>{`Webのアプリケーションであれ、C++のWindowsアプリケーションであれ、書いたプログラミングコードはビルドされて最終的なアプリケーションで実行可能な形式となる。
(※ビルドは最終成果物を生成するための一連の作業、プログラムコードをCPUが理解できる機械語に置き換えるコンパイルもビルドプロセスの一つ。)`}</p>
    <p>{`WebサイトならばHTMLファイルをブラウザで読み込めば終わりではないか？と思うかもしれないがそうではない。ECMA6以上のJavaScriptで書かれた文法をBabelというトランスコンパイラでECMA5相当に変換し、JSやCSSファイルの結合、Minify化を行い、などと多くのことをWebサイトとして配信する前に行う。`}</p>
    <p>{`これにはそれなりにCPUパワーが必要となってきて、特にマルチスレッド能力が高いものがビルド時間が短くなる。この`}<strong parentName="p"><em parentName="strong">{`ビルド時間の短縮がプログラミング作業の生産性に大きな影響を与える`}</em></strong>{`。`}</p>
    <img src={BuildGif} style={{
      width: '100%'
    }} alt="ソースコードのビルド" />
    <p>{`上記は当サイトをビルドしている様子であり、13秒ほどかかっているが、遅いCPUを積むと１分程度は掛かってしまう。一方でより高速なCPUを使うと5秒程で終了できるだろう。`}</p>
    <p>{`またJava言語ならばEclipse、C++の開発ならばVisualStudio、Webの開発ならばVS Codeなど幾つかスタンダードとなるエディタはそれなりに重厚な作りをしており、CPUが低速だと起動時や操作時にもっさりとしてしまう。`}</p>
    <p>{`これらの理由から目安として、総合的なCPUの性能を計るベンチマークテストであるPassMarkで10000点程度は欲しい所である。PassMarkの得点は下記を参考にして欲しい。`}</p>
    <Card {...props} title="CPU性能比較表" description="400を超えるCPUから高機能フィルタにより世代間/ベンチマーク/TDP/コスパ等の絞りこみが行えます。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <h3 {...{
      "id": "高度な3Dゲーム開発をする場合は高性能なGPUを搭載",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%AB%98%E5%BA%A6%E3%81%AA3D%E3%82%B2%E3%83%BC%E3%83%A0%E9%96%8B%E7%99%BA%E3%82%92%E3%81%99%E3%82%8B%E5%A0%B4%E5%90%88%E3%81%AF%E9%AB%98%E6%80%A7%E8%83%BD%E3%81%AAGPU%E3%82%92%E6%90%AD%E8%BC%89",
        "aria-label": "高度な3Dゲーム開発をする場合は高性能なGPUを搭載 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`高度な3Dゲーム開発をする場合は高性能なGPUを搭載`}</h3>
    <p>{`上記の8GB、256GB、PassMark 10000点以上のCPUでほぼ困ることは無いが、高度な3Dゲームの開発をする時はメモリ16GB、数万円のミドルレンジグラフィックボードの搭載は必須と考えた方が良いだろう。`}</p>
    <p>{`また、AI、科学技術計算にはCPUだけで事足りる場合も多いが、規模が大きい計算となるとGPUで並列演算を行い計算の高速化しないと待ち時間が多くなり試行錯誤を繰り返す余裕が無くなってしまう。研究や仕事で使うような場合は特にGPUは搭載した方が良いだろう。`}</p>
    <h2 {...{
      "id": "その他プログラミングに適したパソコンでプラスとなる要素",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%E3%81%AB%E9%81%A9%E3%81%97%E3%81%9F%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%A7%E3%83%97%E3%83%A9%E3%82%B9%E3%81%A8%E3%81%AA%E3%82%8B%E8%A6%81%E7%B4%A0",
        "aria-label": "その他プログラミングに適したパソコンでプラスとなる要素 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他プログラミングに適したパソコンでプラスとなる要素`}</h2>
    <p>{`以上で基本は抑えたが、プログラミングに適したパソコンとして加点となる要素を述べていく。かなりマニアックな部分とはなるので、いいモデルが無ければ別に無視してくれても構わない。`}</p>
    <h3 {...{
      "id": "CPUはIntelだけでなくRyzenもおすすめである",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU%E3%81%AFIntel%E3%81%A0%E3%81%91%E3%81%A7%E3%81%AA%E3%81%8FRyzen%E3%82%82%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%A7%E3%81%82%E3%82%8B",
        "aria-label": "CPUはIntelだけでなくRyzenもおすすめである permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUはIntelだけでなくRyzenもおすすめである`}</h3>
    <Image {...props} name="ryzen" alt="ryzenのCPU" mdxType="Image" />
    <p>{`プログラミングで待ちが発生する時間はBuildを待つ時間であり、この時間を縮めていくことは生産性の改善につながる。`}</p>
    <p>{`Buildにはマルチコアの能力が活かされることが多いため、一般的に`}<strong parentName="p"><em parentName="strong">{`コア数の多いCPUの方がBuild時間が短くて済む`}</em></strong>{`。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`AMDのRyzenはIntelのCore iシリーズと比べてマルチコア能力が価格比で高い`}</em></strong>{`ため、プログラミング作業を中心とする環境ではお得感がある。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Ryzenが良いというよりはマルチコア性能が高ければよい`}</em></strong>{`ので、例えばIntelでもノート向けPやHシリーズはマルチコア性能が高くおすすめである。`}</p>
    <p>{`先に紹介したCPU比較表では純粋にPassMarkが高いものを中心に探せば良いであろう。`}</p>
    <p>{`Intelを少しフォローしておくと、ChromeやAdobe、Officeなど有名なソフトを最適化しているため、ベンチマークの結果と比べて動作が安定し、パフォーマンスが得やすい特徴がある。パソコンでプログラミングのみを行なう訳ではないので総合的に判断したい。`}</p>
    <h3 {...{
      "id": "縦長ディスプレイ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%B8%A6%E9%95%B7%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4",
        "aria-label": "縦長ディスプレイ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`縦長ディスプレイ`}</h3>
    <p>{`プログラミングの行数が増えてくると、全体のソースコードを見渡せるように縦の解像度が欲しくなる。頭の中で表示領域にないコードを念頭に入れつつ書いていくのは脳の負荷が若干上がるため好ましくは無い。`}</p>
    <p>{`FullHD(1920x1080)解像度のものが割安な製品が多い印象だが、`}<strong parentName="p"><em parentName="strong">{`プログラミング用として考えると縦に少し長いWUXGA(1920x1200)解像度が好ましい`}</em></strong>{`。
MacBookやSurfaceの一部のモデルはより縦が長い3:2のアスペクト比を採用しており同様にプログラミング向けと言えるだろう。`}</p>
    <p>{`デスクトップの場合も同様に1920x1200がプログラミングに適したモニターであるが、種類が少ない上に高額な商品が多いため購入はしていない。値段次第と言えるだろう。購入するなら`}<a parentName="p" {...{
        "href": "https://amzn.to/3yyfC2S",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`DellのWUXGAモニタ`}</a>{`がコスパが高くおすすめである。`}</p>
    <h3 {...{
      "id": "Thunderbolt対応",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Thunderbolt%E5%AF%BE%E5%BF%9C",
        "aria-label": "Thunderbolt対応 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thunderbolt対応`}</h3>
    <p>{`映像出力、充電、USBハブとしての役割を１本のType Cケーブルで担うことができる規格がThunderboltである。`}</p>
    <p>{`これに対応することにより、図のように`}<strong parentName="p"><em parentName="strong">{`ケーブルを一本挿すだけでノートパソコンをデスクトップ環境に早変わりさせることが可能`}</em></strong>{`である。`}</p>
    <Image {...props} name="typec_desktop" caption="TypeCを利用したデスクトップ環境の構築" mdxType="Image" />
    <p>{`Thunderboltに対応していなくともPowerDelivery(給電)機能とオルタネートモードという映像出力機能にパソコン側のUSB TypeC端子が対応していれば問題ない。ハイエンドのノートパソコンには間違えなくこの機能は備わっているであろう。`}</p>
    <p>{`図に関して、ノートパソコンは３枚目のモニタとして使用しても、閉じてデュアルディスプレイ環境としても良いだろう。またDisplayPortのデイジーチェーン接続を用いてマルチモニターを実現しているが、`}<a parentName="p" {...{
        "href": "https://amzn.to/3Oj5Drk",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`TypeCハブ`}</a>{`を介して複数画面に出力するのも一般的である。`}</p>
    <p>{`ただ、この機能を実現するために、Thunderboltに対応したパソコンやキーボード、マウスの他、USB TypeC対応のモニタ、Thunderboltに対応したケーブル等を買い揃える必要があり、`}<strong parentName="p"><em parentName="strong">{`合わせて5万円程度はコスト増になる`}</em></strong>{`。`}</p>
    <p>{`割に合わない投資となる可能性はあるが、家での生産性を引き上げることが可能である。`}</p>
    <h3 {...{
      "id": "ノングレア液晶",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8E%E3%83%B3%E3%82%B0%E3%83%AC%E3%82%A2%E6%B6%B2%E6%99%B6",
        "aria-label": "ノングレア液晶 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノングレア液晶`}</h3>
    <p>{`光沢のある液晶を使っていると映像視聴には適しているものの、長時間のプログラミングには目が疲れる場合がある。よって光沢液晶と書いてある場合は注意しよう。`}</p>
    <h2 {...{
      "id": "デスクトップかノートのどちらがおすすめか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%8B%E3%83%8E%E3%83%BC%E3%83%88%E3%81%AE%E3%81%A9%E3%81%A1%E3%82%89%E3%81%8C%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%8B%EF%BC%9F",
        "aria-label": "デスクトップかノートのどちらがおすすめか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デスクトップかノートのどちらがおすすめか？`}</h2>
    <p>{`まずそれぞれのメリットを語ると、デスクトップパソコンの方が馬力のあるCPUを積むことができるため、それだけ`}<strong parentName="p"><em parentName="strong">{`ビルド時間を短縮して快適に作業を行うことができる`}</em></strong>{`。`}</p>
    <p>{`また、同サイズのモニターを２つおいてデュアルディスプレイ環境を構築しやすく、好きなキーボードを使用できるなど`}<strong parentName="p"><em parentName="strong">{`開発環境でも圧倒的にノートパソコンに優る`}</em></strong>{`。`}</p>
    <p>{`一方で勉強会やハッカソンのような大会に出る、プログラミングのスクールに通うというような人は当然ノートパソコンでないとこのような事は不可能である。`}</p>
    <p>{`デスクトップとノートを組み合わせる場合もあるので、選択肢としては以下のようになる。`}</p>
    <BasicTable className='center' mdxType="BasicTable">
  <caption>デスクトップとノートの組み合わせ方</caption>
  <thead>
    <tr>
      <th>デバイス</th>
      <th>処理速度</th>
      <th>開発環境</th>
      <th>携帯性</th>
      <th>データ管理</th>
      <th>コスト</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>デスクトップのみ</td>
      <td>〇</td>
      <td>〇</td>
      <td>×</td>
      <td>〇</td>
      <td>〇</td>
    </tr>
    <tr>
      <td>ノートパソコン with Thunderbolt</td>
      <td>△</td>
      <td>〇</td>
      <td>〇</td>
      <td>〇</td>
      <td>△</td>
    </tr>
    <tr>
      <td>ノートパソコン + デスクトップ</td>
      <td>〇</td>
      <td>〇</td>
      <td>〇</td>
      <td>△</td>
      <td>×</td>
    </tr>
    <tr>
      <td>ノートパソコンのみ</td>
      <td>△</td>
      <td>△</td>
      <td>〇</td>
      <td>〇</td>
      <td>〇</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`コストをかけたくない場合は、携帯性を犠牲にするか処理速度を犠牲にするかを秤にかける必要がある。`}</p>
    <p>{`次にコストをかけて良い場合はデスクトップPCとモバイルノートを購入し、家ではデスクトップ、出先ではモバイルノートを活用し、データはGithubなどで共有すると良いだろう。`}</p>
    <p>{`ただ、この方法でもデータのやり取りがやや煩雑になり、またソフトウェアの開発環境をノートとデスクトップの両方で準備しなくてはならず手間である。`}</p>
    <p>{`この問題を解決する手段として、先にも述べたがThunderbolt(またはPowerDelivery + オルタネートモード)対応のパソコンとTypeC入力ができるモニターを購入してノートパソコンをデスクトップのようにして使う方法もある。`}</p>
    <p>{`コストが掛かり、処理速度もデスクトップに劣るが、快適な環境でプログラミングに打ち込むことができる。`}<strong parentName="p"><em parentName="strong">{`まずはThunderbolt対応のノートパソコンを将来を考えて購入しておくのも一つの手`}</em></strong>{`である。`}</p>
    <h2 {...{
      "id": "プログラミングにおすすめのパソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%E3%81%AB%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "プログラミングにおすすめのパソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`プログラミングにおすすめのパソコン`}</h2>
    <p>{`それでは最後におすすめのパソコンを紹介しておく。プログラミングの種類により、求められるスペックが異なるため、それに応じたパソコンを選択した。`}</p>
    <p>{`価格が手頃かつ、それぞれのプログラミングを行うのに十分なスペックを持ったパソコンを選んでいるので参考にして欲しい。リンク先は独自にプログラミングに適したスペックへフィルタをかけている。`}</p>
    <BasicTable mdxType="BasicTable">
  <caption>プログラミングにおすすめのパソコン製品</caption>
  <thead>
		<tr>
			<th>プログラミングの種類</th>
			<th>おすすめモデル</th>
		</tr>
  </thead>
	<tbody>
		<tr>
			<td>Webプログラミング</td>
			<td>
            <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887944551&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Flaptops%2Fresults%2F%3FvisibleDatas%3D2195%253A14-14.9%2525E5%25259E%25258B%253B2114%253AAMD%252520Ryzen%2525E2%252584%2525A2%2525205%253B2118%253A1920x1200%2525E6%252590%2525AD%2525E8%2525BC%252589%2525E5%25258F%2525AF%2525E8%252583%2525BD%26cid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887944551" height="1" width="0" border="0" alt="" />Lenovo Ryzen 5 1920x1200モデル</a>
          </td>
		</tr>
		<tr>
			<td>iOSプログラミング</td>
			<td><a href="https://amzn.to/2VAO0vc" rel="nofollow noopener" target="_blank">Macbook Air</a></td>
		</tr>
		<tr>
			<td>科学技術計算</td>
			<td rowSpan="3">
         <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=43708&murl=https%3A%2F%2Fjp.store.asus.com%2Fstore%2Fasusjp%2Fja_JP%2Flist%2FThemeID.4850018000%2FcategoryID.5251091300" rel="nofollow noopener" target="_blank">ROG Zephyrus G14</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=623694.1&type=10" /></td>
		</tr>
		<tr>
			<td>AIプログラミング</td>
		</tr>
    <tr>
			<td>ゲームプログラミング(ノート)</td>
		</tr>
		<tr>
			<td>ゲームプログラミング（デスクトップ)</td>
			<td><a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0" /> |   <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886899662" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886899662" height="1" width="1" border="0" alt="" />SEVEN</a></td>
		</tr>
	</tbody>
    </BasicTable>
    <h3 {...{
      "id": "Dynabook-RZ--最強14インチモバイルノート",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Dynabook-RZ--%E6%9C%80%E5%BC%B714%E3%82%A4%E3%83%B3%E3%83%81%E3%83%A2%E3%83%90%E3%82%A4%E3%83%AB%E3%83%8E%E3%83%BC%E3%83%88",
        "aria-label": "Dynabook RZ  最強14インチモバイルノート permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dynabook RZ | 最強14インチモバイルノート`}</h3>
    <p>{`Webプログラミングを行なうならば最強のノートパソコン。14インチで16:10という快適な作業には最適なサイズ感とアスペクト比。1kgを切る軽量さ、複数ディスプレイに簡単に出力できる端子の豊富さ、20時間超のバッテリーなど完成を極めたパソコン。`}</p>
    <p>{`ビジネス向け最強ノートとしても定評があるが、特に`}<strong parentName="p"><em parentName="strong">{`クリエイティブな作業を行う人間にとっては最高効率を発揮できるノートパソコン`}</em></strong>{`であり、開発環境に投資できるならば購入したい。`}</p>
    <p>{`また、GPUは搭載されていないが、最強クラスのCPUを搭載しているため、科学技術計算やAIプログラミングにも適用できる。`}</p>
    <p>{`当サイト限定の`}<a parentName="p" {...{
        "href": "https://linksynergy.jrs5.com/fs-bin/click?id=xsv0FDnJs1M&offerid=233988.10001860&type=3&subid=0",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`特設サイト`}</a><img parentName="p" {...{
        "src": "https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.10001860&type=3&subid=0%22/",
        "alt": "''"
      }}></img>{`から買うと`}<strong parentName="p"><em parentName="strong">{`プレミア価格で購入できるため必ず活用したい`}</em></strong>{`。【ID: `}<b>{`dyna204cls`}</b>{` PASS: `}<b>{`T8Y7GRSV`}</b>{` 】。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://linksynergy.jrs5.com/deeplink?id=xsv0FDnJs1M&mid=36508&murl=https%3A%2F%2Fdynabook.com%2Fdirect%2Fmobile-note-pc%2Frz-series.html" target="_blank" rel="nofollow noopener">Dynabook RZシリーズ</a>
      <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=233988.1&type=10" />
    </SmallBox>
    <h3 {...{
      "id": "Lenovo-Ryzen-5モデル--神コスパノートPC",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Lenovo-Ryzen-5%E3%83%A2%E3%83%87%E3%83%AB--%E7%A5%9E%E3%82%B3%E3%82%B9%E3%83%91%E3%83%8E%E3%83%BC%E3%83%88PC",
        "aria-label": "Lenovo Ryzen 5モデル  神コスパノートPC permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lenovo Ryzen 5モデル | 神コスパノートPC`}</h3>
    <p>{`Webプログラミングを本格的に行いたいがコストは抑えたいという場合はLenovoのRyzen5搭載モデルがコストパフォーマンスの観点からおすすめである。`}</p>
    <p>{`Ryzen5はRyzenのミドルレンジCPUでありボリュームゾーンのためパフォーマンスの割に価格競争力が高い。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`10万円程度で高速なプログラミング環境が手に入るため非常におすすめ`}</em></strong>{`である。外付けモニターでも一台購入すれば環境としては十分プロレベルである。`}</p>
    <p>{`更に解像度が1920x1200のものだとより快適にプログラミングが行なえる。注意点として、たまにモニタが光沢の場合があるので、ディスプレイの仕様はキチンとチェックしておこう。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887944551&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Flaptops%2Fresults%2F%3FvisibleDatas%3D2195%253A14-14.9%2525E5%25259E%25258B%253B2114%253AAMD%252520Ryzen%2525E2%252584%2525A2%2525205%253B2118%253A1920x1200%2525E6%252590%2525AD%2525E8%2525BC%252589%2525E5%25258F%2525AF%2525E8%252583%2525BD%26cid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887944551" height="1" width="0" border="0" alt="" />Lenovo Ryzen 5 1920x1200モデル</a>
    </SmallBox>
    <h3 {...{
      "id": "Macbook-Air--モバイル開発の定番",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Macbook-Air--%E3%83%A2%E3%83%90%E3%82%A4%E3%83%AB%E9%96%8B%E7%99%BA%E3%81%AE%E5%AE%9A%E7%95%AA",
        "aria-label": "Macbook Air  モバイル開発の定番 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Macbook Air | モバイル開発の定番`}</h3>
    <p>{`iOSアプリケーションの開発をしたい場合はMacBook一択であるが、ノートパッドの使いまわしが優れているため、自習室やカフェなどでプログラミングをしたい場合も非常に適したパソコンである。`}</p>
    <p>{`値段は安くはなく、Airという割には重量がある、Windowsに慣れている場合は使いづらい、USB Type-A端子が無いなど様々な問題もあるが、動作は安定しており当然ながら筐体の質感は良い。`}</p>
    <p>{`まあ金持ちは処理速度の観点でMacbook Proの所有者が多いが、MacBook Airでも十分にパワフルなので、特別な理由がなければMacbook Airの一番安いモデルで問題は無いだろう。`}</p>
    <SmallBox type="word accent" text="Amazonへ" mdxType="SmallBox">
  <a href="https://amzn.to/2VAO0vc" rel="nofollow noopener" target="_blank">Macbook Air</a>
    </SmallBox>
    <h3 {...{
      "id": "ASUS-LOG-Zephyrus-G14--全方位対応の最強ノートパソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ASUS-LOG-Zephyrus-G14--%E5%85%A8%E6%96%B9%E4%BD%8D%E5%AF%BE%E5%BF%9C%E3%81%AE%E6%9C%80%E5%BC%B7%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "ASUS LOG Zephyrus G14  全方位対応の最強ノートパソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ASUS LOG Zephyrus G14 | 全方位対応の最強ノートパソコン`}</h3>
    <p>{`ASUSの最強ゲーミングノートパソコン、NvidiaのGPUを搭載しており、これ一台でAI学習、グラフィック出力を伴う科学技術計算、ゲーム開発と`}<strong parentName="p"><em parentName="strong">{`あらゆるプログラミング開発を快適に行なうことが可能`}</em></strong>{`である。`}</p>
    <p>{`ノングレア液晶や16:10の縦長アスペクト比などが採用されており、実際ゲーマーだけではなく、`}<strong parentName="p"><em parentName="strong">{`ゲーム開発者向けにも作られたノートパソコン`}</em></strong>{`でもある。`}</p>
    <p>{`重量も1.65kgと`}<strong parentName="p"><em parentName="strong">{`ハイエンドなGPUを搭載していることを考慮すると驚異的な軽さを実現している`}</em></strong>{`ため、頻繁に持ち歩く人でも問題なく使うことができる。`}</p>
    <p>{`とにかくあらゆるプログラミング作業で場所を問わず最高の開発環境を実現したい人にはうってつけのノートパソコンである。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=43708&murl=https%3A%2F%2Fjp.store.asus.com%2Fstore%2Fasusjp%2Fja_JP%2Flist%2FThemeID.4850018000%2FcategoryID.5251091300" rel="nofollow noopener" target="_blank">ROG Zephyrus G14シリーズ</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=623694.1&type=10" />
    </SmallBox>
    <h3 {...{
      "id": "パソコン工房-デスクトップPC--圧倒的パフォーマンスを実現",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E5%B7%A5%E6%88%BF-%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97PC--%E5%9C%A7%E5%80%92%E7%9A%84%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%82%92%E5%AE%9F%E7%8F%BE",
        "aria-label": "パソコン工房 デスクトップPC  圧倒的パフォーマンスを実現 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコン工房 デスクトップPC | 圧倒的パフォーマンスを実現`}</h3>
    <p>{`本格的なゲームプログラミングを行いたい。超高速なBuild環境を構築して時間効率を徹底的に上げていきたい場合にはRyzen 9などの重厚なCPUとGPUを搭載したデスクトップマシンを用意しよう。重めのAI学習、数値計算処理など何であれ高速にこなしてくれる。`}</p>
    <p>{`老舗のBTOメーカーであるパソコン工房のデスクトップパソコンは、細部にわたるパーツ選択や安定性には定評があり、コストパフォーマンスもかなり高いので、そのような開発に最適な選択の一つであろう。`}</p>
    <p>{`あるいはよりきめ細かい注文をするならばBTOメーカーのSEVENがおすすめである。値段に糸目をつけなければ100万円近くのモンスターマシンを発注することすら可能。またコストパフォーマンスもパソコン工房に劣らず高い秋葉原の老舗である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=83593.10000209&type=3&subid=0" target="_blank" rel="nofollow noopener">パソコン工房</a><img width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=83593.10000209&type=3&subid=0" /> |   <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886899662" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886899662" height="1" width="1" border="0" alt="" />SEVEN</a>
    </SmallBox>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      